/*
На входе отсортированный массив каждая запись - ['субъект рф', 'значение для рейтинга']
выход - ['субъект рф', 'значение для рейтинга', 'место в рейтинге']
изменяет исходный массив
 */
export function addRatingColumn(ratingData){
    let ratingPos = 1
    ratingData[0][2] = ratingPos
    for (let i = 1; i < ratingData.length; i++) {
        if(i === ratingData.length || ratingData[i-1][1] !== ratingData[i][1]){
            ratingPos++
        }
        ratingData[i][2] = ratingPos
    }
}
