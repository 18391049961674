<template>
  <v-expansion-panels>
    <v-expansion-panel class="level2">
      <v-expansion-panel-header>
        <div class="report-list__item">
          <a class="report-list__title">
            <button class="toggleSwitch">
              <svg class="closed" fill="none" height="24" viewBox="0 0 24 24"
                   width="24"
                   xmlns="http://www.w3.org/2000/svg">
                <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" fill="#1551D0"/>
              </svg>
              <svg class="opened" fill="none" height="24" viewBox="0 0 24 24"
                   width="24"
                   xmlns="http://www.w3.org/2000/svg">
                <path d="M14.5 12L9.5 7L9.5 17L14.5 12Z" fill="#1551D0"/>
              </svg>
            </button>
            <span>{{title}}</span>
          </a>
          <div class="report-list__download" @click.stop="download()"
               v-if="['ROLE_ADMIN', 'ROLE_VNII'].includes($user.role)">
          </div>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <ResultMonitorRatingContent
            :period="period"
            :level="level"
            :config="config"
            :legend="legend"
            :report-data-function="reportDataFunction"
            :region-and-department-info="regionAndDepartmentInfo"
            :only-current-region="onlyCurrentRegion"
        ></ResultMonitorRatingContent>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <LoaderDialog :show="showLoaderDialog"></LoaderDialog>
  </v-expansion-panels>
</template>

<script>

import LoaderDialog from "@/components/elements/LoaderDialog";
import ResultMonitorRatingContent from "@/components/resultmonitor_new/ResultMonitorRatingContent";
import {addRatingColumn} from "@/modules/ReportUtils";
import {getPeriodName} from "@/modules/periods";

export default {
  name: 'ResultMonitorRating',
  components: {ResultMonitorRatingContent, LoaderDialog},
  props: ['config', 'period', 'level', 'regionAndDepartmentInfo', 'title', 'reportDataFunction', 'legend', 'onlyCurrentRegion'],

  data: () => ({
    showLoaderDialog: false
  }),
  methods: {
    async download(){
      this.showLoaderDialog = true
      let data = await this.reportDataFunction(this.level, this.period, this.regionAndDepartmentInfo)
      if(this.config){
        this.config.configParsed = JSON.parse(this.config.configJson)
        let regionNamesToInclude = this.$cznDepartmentsByRegionList
            .filter(e=>this.config.configParsed.regions.includes(e.code))
            .map(e=>e.name)
        data = data.filter(e=>regionNamesToInclude.includes(e[0]))
      }
      addRatingColumn(data)
      data = this.adaptDataForExport(data) //todo
      await this.$downloadApi("Рейтинг.xlsx","/export/xlsx", data, true)
      this.showLoaderDialog = false
    },

    adaptDataForExport(data){
      let exportData = {
        headerCells: [
          {
            caption: "Субъект РФ/ЦЗН",
            width: 5000,
          },
          {
            caption: "Значение",
            width: 5000,
          },
          {
            caption: "Место в рейтинге",
            width: 5000,
          },
          {
            caption: "Период",
            width: 5000,
          },
          {
            caption: "Название рейтинга",
            width: 10000,
          }
        ],
        rows: []
      }

      for(let i = 0; i < data.length; i++){
        let row = data[i];
        let newRow = [
          {
            cellDataType: "STRING",
            value: row[0]
          },
          {
            cellDataType: "DECIMAL",
            value: row[1]
          },
          {
            cellDataType: typeof row[2] === 'number' ? 'INTEGER' : 'STRING',
            value: row[2]
          }
        ];

        if (i === 0) {
          newRow.push(
              {
                cellDataType: "STRING",
                value: this.periodName
              },
              {
                cellDataType: "STRING",
                value: this.title
              }
          );
        }

        exportData.rows.push(newRow);
      }

      return exportData
    }
  },
  computed: {
    periodName(){
      return getPeriodName(this.period)
    }
  },
  beforeMount() {
  }
}
</script>

